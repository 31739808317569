var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "org" }, [
    _c("div", { staticClass: "org-head" }, [_vm._v("抢单的入驻机构")]),
    _c(
      "div",
      { staticClass: "org-body" },
      _vm._l(_vm.list, function(item, index) {
        return _c("OrgItem", {
          key: index,
          attrs: { isEnd: _vm.isEnd, entity: item },
          on: { submit: _vm.onSubmit, reject: _vm.onReject }
        })
      }),
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }