<template>
  <div class="item">
    <router-link :to="{path:'/org/detail/' + entity.orgId}" class="item-left">
      <div>
        <img :src="entity.orgLogo" alt="">
        <span>{{ entity.orgName }}</span>
      </div>
      <img src="@/assets/img/work/icon_arrow_down.svg" alt="">
    </router-link>
    <div class="item-right">
      <template v-if="entity.status == 0 && !isEditing && !isEnd">
        <mt-button size="small" @click="onResolve" class="ml20" type="primary">合作</mt-button>
        <mt-button size="small" @click="onReject" class="ml20">不合作</mt-button>
      </template>
      <template v-if="isEditing">
        <div class="item-row">
          <input placeholder="对接人姓名" v-model="formData.username" />
          <input maxlength="11" placeholder="对接人联系方式" v-model="formData.mobile" />
        </div>
        <div class="item-row">
          <mt-button size="small" @click="onSubmit" type="primary">提交</mt-button>
          <mt-button size="small" class="ml20" @click="onCancel">取消</mt-button>
        </div>
      </template>
      <mt-button v-if="entity.status == 2" disabled size="small" class="ml20">不合作</mt-button>
      <template v-if="entity.status == 1">
        <span>对接人：{{ entity.username }}  {{ entity.mobile }}</span>
        <mt-button size="small" class="ml20" disabled>已合作</mt-button>
      </template>
    </div>
  </div>
</template>
<script>
import { Toast } from 'mint-ui'
import { validPhone } from '@/utils/util'

export default {
  components: {
  },
  props: {
    entity: {
      type: Object,
      default: () => {
        return {}
      }
    },
    isEnd: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      isEditing: false,
      formData: {
        username: '',
        mobile: ''
      }
    }
  },
  methods: {
    async onReject () {
      this.$emit('reject', this.entity.orgId)
    },
    onResolve () {
      this.isEditing = true
    },
    onSubmit () {
      const { username, mobile } = this.formData
      if (!username) return Toast('请输入对接人姓名')
      if (!mobile) return Toast('请输入对接人联系方式')
      if (!validPhone(mobile)) return Toast('请输入正确的联系方式')
      this.$emit('submit', {
        ...this.formData,
        orgId: this.entity.orgId
      })
      this.onCancel()
    },
    onCancel () {
      this.isEditing = false
    }
  }
}
</script>
<style lang="less" scoped>
.item{
  display: flex;
  flex-direction: column;
  margin-top: 15px;
  border-bottom: 1px solid #F0F0F0;
  &:last-child{
    border-bottom: none;
  }
  &-left{
    display: flex;
    flex-direction: row;
    align-items: center;
    height:45px;
    background:rgba(242,242,242,1);
    padding: 0 12px;
    & > div{
      flex: 1;
      display: flex;
      flex-direction: row;
      align-items: center;
      img{
        width:30px;
        height:30px;
        border-radius:50%;
        display: block;
        background-color: lightgray;
      }
      span{
        font-size:13px;
        font-weight: bold;
        margin-left: 10px;
      }
    }
    & > img{
      width: 10px;
      transform: rotate(-90deg);
    }
  }
  &-right{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    font-size: 14px;
    flex-wrap: wrap;
    padding: 15px 0;
  }
  &-row{
    width: 100%;
    input{
      margin-top: 10px;
      border: 1px solid #f2f2f2;
      height: 35px;
      color: #333333;
      font-size: 14px;
      padding: 0 10px;
      width: 100%;
      box-sizing: border-box;
    }
    margin-bottom: 10px;
  }
}
.ml20{
  margin-left: 10px;
}
</style>
