<template>
  <div class="admin-project-detail">
    <section><TopInfo :entity="entity" /></section>
    <section><Intro :showmore="true" :entity="entity"/></section>
    <section><Attachment :entity="entity"/></section>
    <section>
      <Org :isEnd="entity.status >= 4" :projectId="projectId" v-if="!isOrgRole" />
      <Progress :projectId="projectId" :companyId="companyId" v-else />
    </section>
    <div class="footer">
      <mt-button
        :loading="finishing"
        v-if="!isOrgRole"
        :disabled="entity.status >= 4"
        @click="onFinish"
        type="primary">
        {{ 4 > entity.status ? '结束' :'已结束' }}
      </mt-button>
    </div>
  </div>
</template>
<script>
import shared from '../shared'
import { getDetail, toFinish } from '@/api/project'
import comps from './comps'
import { MessageBox } from 'mint-ui'
import { mapGetters } from 'vuex'

export default {
  components: {
    ...comps,
    ...shared
  },
  data () {
    const { projectId } = this.$route.params
    return {
      projectId,
      entity: {},
      finishing: false
    }
  },
  computed: {
    ...mapGetters(['type', 'companyId']),
    role () {
      return this.type
    },
    isOrgRole () {
      return this.role === 'org' || this.role === 'employee'
    }
  },
  mounted () {
    this.loadData()
  },
  methods: {

    async loadData () {
      const { code, result } = await getDetail(this.projectId)
      if (code === 200) {
        this.entity = result
      }
    },

    // 结束项目
    onFinish () {
      MessageBox({
        title: '提示',
        message: '是否确认结束该项目?',
        showCancelButton: true
      }).then(async (action) => {
        if (action === 'confirm') {
          try {
            this.finishing = true
            const fd = new FormData()
            fd.append('projectId', this.projectId)
            await toFinish(fd)
            this.loadData()
          } catch (error) {
          } finally {
            this.finishing = true
          }
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>
.admin-project-detail{
  min-height: 100vh;
  background-color: #f5f5f5;
  padding-top: 10px;
  section{
    padding: 0 15px;
    margin-bottom: 10px;
  }
  .footer{
    display: flex;
    padding: 30px 0 40px 0;
    align-items: center;
    justify-content: center;
    .mint-button{
      width: 250px;
      font-size: 15px;
      border-radius:41px;
    }
  }
}
</style>
