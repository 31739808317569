<template>
  <div class="org">
    <div class="org-head">抢单的入驻机构</div>
    <div class="org-body">
      <OrgItem
        :isEnd="isEnd"
        @submit="onSubmit"
        @reject="onReject"
        :entity="item"
        v-for="(item, index) in list"
        :key="index" />
    </div>
  </div>
</template>
<script>
import OrgItem from './OrgItem'
import { listMarksOrg, toNoCooperation, toCooperation } from '@/api/project'
import { MessageBox } from 'mint-ui'

export default {
  components: {
    OrgItem
  },
  props: {
    projectId: {
      type: String,
      default: ''
    },
    isEnd: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      list: []
      // list: [{
      //   status: 0,
      //   orgLogo: '',
      //   orgName: '测试3',
      //   orgId: 1
      // }, {
      //   status: 1,
      //   orgLogo: '',
      //   orgName: '测试2',
      //   ordId: 2
      // }, {
      //   status: 2,
      //   orgLogo: '',
      //   orgName: '测试1',
      //   ordId: 2
      // }]
    }
  },
  mounted () {
    this.loadData()
  },
  methods: {
    async loadData () {
      const { respCode, data } = await listMarksOrg({
        projectId: this.projectId
      })
      if (respCode === 0) {
        this.list = data
      }
    },
    async onSubmit (obj) {
      const fd = new FormData()
      fd.append('projectId', this.projectId)
      for (const key in obj) {
        fd.append(key, obj[key])
      }
      await toCooperation(fd)
      this.loadData()
    },
    onReject (orgId) {
      MessageBox({
        title: '提示',
        message: '是否确认不合作?',
        showCancelButton: true
      }).then(async (action) => {
        if (action === 'confirm') {
          try {
            const fd = new FormData()
            fd.append('projectId', this.projectId)
            fd.append('orgId', orgId)
            await toNoCooperation(fd)
            this.loadData()
          } catch (error) {
          }
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>
.org{
  padding: 15px;
  background:rgba(255,255,255,1);
  box-shadow:0px 0px 3px 0px rgba(23,140,249,0.1);
  border-radius:6px;
  &-head{
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size:15px;
    padding-left: 13px;
    position: relative;
    line-height: 16px;

    &::after{
      content: "";
      position: absolute;
      left: 0;
      width: 3px;
      height: 16px;
      background:rgba(23,140,249,1);
    }
  }
}
</style>
