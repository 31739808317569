var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "admin-project-detail" }, [
    _c("section", [_c("TopInfo", { attrs: { entity: _vm.entity } })], 1),
    _c(
      "section",
      [_c("Intro", { attrs: { showmore: true, entity: _vm.entity } })],
      1
    ),
    _c("section", [_c("Attachment", { attrs: { entity: _vm.entity } })], 1),
    _c(
      "section",
      [
        !_vm.isOrgRole
          ? _c("Org", {
              attrs: { isEnd: _vm.entity.status >= 4, projectId: _vm.projectId }
            })
          : _c("Progress", {
              attrs: { projectId: _vm.projectId, companyId: _vm.companyId }
            })
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "footer" },
      [
        !_vm.isOrgRole
          ? _c(
              "mt-button",
              {
                attrs: {
                  loading: _vm.finishing,
                  disabled: _vm.entity.status >= 4,
                  type: "primary"
                },
                on: { click: _vm.onFinish }
              },
              [
                _vm._v(
                  " " + _vm._s(4 > _vm.entity.status ? "结束" : "已结束") + " "
                )
              ]
            )
          : _vm._e()
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }