<template>
  <div class="progress">
    <div class="progress-head">对接进度</div>
    <div class="progress-body">
      <ul class="mytimeline">
        <li
          :class="['mytimeline-item', item.active && 'active', index === list.length - 1 && 'mytimeline-item-last']"
          v-for="(item, index) in list"
          :key="index"
        >
          <div class="mytimeline-item-tail"></div>
          <div class="mytimeline-item-head mytimeline-item-head-custom mytimeline-item-head-blue">
            <span class="timeline-dot">{{ index + 1 }}</span>
          </div>
          <div class="mytimeline-item-content">
            <div class="timeline-row">
              <span class="timeline-row__title">{{ item.title }}</span>
              <div class="timeline-row__right">
                <span class="timeline-row__status">{{ item.status }}</span>
              </div>
            </div>
            <div v-if="item.user" class="timeline-user">
              <img src="~@/assets/img/project/icon_visit_gray@2x.png" alt="" />
              <span>{{ item.user }}</span>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import { getMarksStatus } from '@/api/project'

export default {
  props: {
    projectId: {
      type: [Number, String],
      default: ''
    },
    companyId: {
      type: [Number, String],
      default: 0
    }
  },
  data() {
    return {
      list: []
    }
  },
  mounted() {
    this.loadData()
  },
  methods: {
    async loadData() {
      const { success, result } = await getMarksStatus({ projectId: this.projectId, orgId: this.companyId })
      if (success) {
        switch (Number(result.status)) {
          case 0:
            this.list = [{ title: '抢单', status: '已抢单', active: true }, { title: '合作', status: '待合作' }]
            break
          case 1:
            this.list = [
              { title: '抢单', status: '已抢单', active: true },
              { title: '合作', status: '已合作', active: true, user: `对接人：${result.username} ${result.mobile}` }
            ]
            break
          case 2:
            this.list = [
              { title: '抢单', status: '已抢单', active: true },
              { title: '合作', status: '不合作', active: true }
            ]
            break
          default:
            break
        }
      }
    }
  }
}
</script>
<style lang="less" scoped>
.progress {
  padding: 15px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 0px 3px 0px rgba(23, 140, 249, 0.1);
  border-radius: 6px;
  &-head {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 15px;
    padding-left: 13px;
    position: relative;
    line-height: 16px;

    &::after {
      content: '';
      position: absolute;
      left: 0;
      width: 3px;
      height: 16px;
      background: rgba(23, 140, 249, 1);
    }
  }

  &-body {
    padding: 30px 15px 0 15px;
  }

  .timeline {
    &-dot {
      width: 20px;
      height: 20px;
      background: #bcbcbc;
      border-radius: 50%;
      display: inline-block;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 12px;
      color: white;
      font-weight: bold;
    }
    &-user {
      height: 32px;
      background: rgba(242, 242, 242, 1);
      padding: 0 12px;
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-top: 12px;
      margin-left: 15px;
      img {
        width: 12px;
        height: 14px;
        margin-right: 10px;
      }
    }
    &-row {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      min-height: 20px;
      padding-left: 15px;
      position: relative;
      line-height: 1.2;
      top: 2px;
      &__title {
        font-size: 15px;
      }
      &__right {
        display: flex;
        flex-direction: row;
        align-items: center;
      }
      &__status {
        color: #999999;
      }
    }
  }

  .active {
    .timeline-dot {
      background-color: #178cf9;
    }
    .timeline-row__title {
      color: #178cf9;
    }
  }

  .mytimeline {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    color: rgba(0, 0, 0, 0.65);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5;
    -webkit-font-feature-settings: 'tnum';
    font-feature-settings: 'tnum';
    margin: 0;
    padding: 0;
    list-style: none;
  }
  .mytimeline-item {
    position: relative;
    margin: 0;
    padding: 0 0 20px;
    font-size: 14px;
    list-style: none;
    padding-bottom: 30px;
  }
  .mytimeline-item-tail {
    position: absolute;
    top: 0.75em;
    left: 4px;
    height: 100%;
    border-left: 2px solid #e8e8e8;
  }
  .mytimeline-item-head-custom {
    position: absolute;
    top: 5.5px;
    left: 5px;
    width: auto;
    height: auto;
    margin-top: 0;
    padding: 3px 1px;
    line-height: 1;
    text-align: center;
    border: 0;
    border-radius: 0;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
  .mytimeline-item-head-blue {
    color: #1890ff;
    border-color: #1890ff;
  }
  .mytimeline-item-last > .mytimeline-item-content {
    min-height: 48px;
  }
  .mytimeline-item-content {
    position: relative;
    top: -5px;
    margin: 0 0 0 18px;
  }
  .mytimeline-item-last > .mytimeline-item-tail {
    display: none;
  }
  .mytimeline-item-last {
    padding-bottom: 0;
  }
}
</style>
