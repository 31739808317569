var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "progress" }, [
    _c("div", { staticClass: "progress-head" }, [_vm._v("对接进度")]),
    _c("div", { staticClass: "progress-body" }, [
      _c(
        "ul",
        { staticClass: "mytimeline" },
        _vm._l(_vm.list, function(item, index) {
          return _c(
            "li",
            {
              key: index,
              class: [
                "mytimeline-item",
                item.active && "active",
                index === _vm.list.length - 1 && "mytimeline-item-last"
              ]
            },
            [
              _c("div", { staticClass: "mytimeline-item-tail" }),
              _c(
                "div",
                {
                  staticClass:
                    "mytimeline-item-head mytimeline-item-head-custom mytimeline-item-head-blue"
                },
                [
                  _c("span", { staticClass: "timeline-dot" }, [
                    _vm._v(_vm._s(index + 1))
                  ])
                ]
              ),
              _c("div", { staticClass: "mytimeline-item-content" }, [
                _c("div", { staticClass: "timeline-row" }, [
                  _c("span", { staticClass: "timeline-row__title" }, [
                    _vm._v(_vm._s(item.title))
                  ]),
                  _c("div", { staticClass: "timeline-row__right" }, [
                    _c("span", { staticClass: "timeline-row__status" }, [
                      _vm._v(_vm._s(item.status))
                    ])
                  ])
                ]),
                item.user
                  ? _c("div", { staticClass: "timeline-user" }, [
                      _c("img", {
                        attrs: {
                          src: require("@/assets/img/project/icon_visit_gray@2x.png"),
                          alt: ""
                        }
                      }),
                      _c("span", [_vm._v(_vm._s(item.user))])
                    ])
                  : _vm._e()
              ])
            ]
          )
        }),
        0
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }