var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "item" },
    [
      _c(
        "router-link",
        {
          staticClass: "item-left",
          attrs: { to: { path: "/org/detail/" + _vm.entity.orgId } }
        },
        [
          _c("div", [
            _c("img", { attrs: { src: _vm.entity.orgLogo, alt: "" } }),
            _c("span", [_vm._v(_vm._s(_vm.entity.orgName))])
          ]),
          _c("img", {
            attrs: {
              src: require("@/assets/img/work/icon_arrow_down.svg"),
              alt: ""
            }
          })
        ]
      ),
      _c(
        "div",
        { staticClass: "item-right" },
        [
          _vm.entity.status == 0 && !_vm.isEditing && !_vm.isEnd
            ? [
                _c(
                  "mt-button",
                  {
                    staticClass: "ml20",
                    attrs: { size: "small", type: "primary" },
                    on: { click: _vm.onResolve }
                  },
                  [_vm._v("合作")]
                ),
                _c(
                  "mt-button",
                  {
                    staticClass: "ml20",
                    attrs: { size: "small" },
                    on: { click: _vm.onReject }
                  },
                  [_vm._v("不合作")]
                )
              ]
            : _vm._e(),
          _vm.isEditing
            ? [
                _c("div", { staticClass: "item-row" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.formData.username,
                        expression: "formData.username"
                      }
                    ],
                    attrs: { placeholder: "对接人姓名" },
                    domProps: { value: _vm.formData.username },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.formData, "username", $event.target.value)
                      }
                    }
                  }),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.formData.mobile,
                        expression: "formData.mobile"
                      }
                    ],
                    attrs: { maxlength: "11", placeholder: "对接人联系方式" },
                    domProps: { value: _vm.formData.mobile },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.formData, "mobile", $event.target.value)
                      }
                    }
                  })
                ]),
                _c(
                  "div",
                  { staticClass: "item-row" },
                  [
                    _c(
                      "mt-button",
                      {
                        attrs: { size: "small", type: "primary" },
                        on: { click: _vm.onSubmit }
                      },
                      [_vm._v("提交")]
                    ),
                    _c(
                      "mt-button",
                      {
                        staticClass: "ml20",
                        attrs: { size: "small" },
                        on: { click: _vm.onCancel }
                      },
                      [_vm._v("取消")]
                    )
                  ],
                  1
                )
              ]
            : _vm._e(),
          _vm.entity.status == 2
            ? _c(
                "mt-button",
                { staticClass: "ml20", attrs: { disabled: "", size: "small" } },
                [_vm._v("不合作")]
              )
            : _vm._e(),
          _vm.entity.status == 1
            ? [
                _c("span", [
                  _vm._v(
                    "对接人：" +
                      _vm._s(_vm.entity.username) +
                      " " +
                      _vm._s(_vm.entity.mobile)
                  )
                ]),
                _c(
                  "mt-button",
                  {
                    staticClass: "ml20",
                    attrs: { size: "small", disabled: "" }
                  },
                  [_vm._v("已合作")]
                )
              ]
            : _vm._e()
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }